/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { CircularProgress, Typography, Box } from '@mui/material'

type CountdownProps = { deadline: string }

const Countdown: FC<CountdownProps> = ({ deadline }) => {
  const calculateDaysLeft = () => {
    const now: any = new Date()
    const endDate: any = new Date(deadline)
    const timeDifference: number = endDate - now
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    return daysLeft >= 0 ? daysLeft : 0
  }

  const [days, setDays] = useState(calculateDaysLeft())

  useEffect(() => {
    const timer = setInterval(
      () => {
        setDays(calculateDaysLeft())
      },
      1000 * 60 * 60 * 24
    )

    return () => clearInterval(timer)
  }, [deadline])

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <CircularProgress
        variant='determinate'
        value={(days / 365) * 10000}
        size={42}
        thickness={4}
        sx={{
          color: '#527EF7'
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'>
        <Typography sx={{ fontSize: '11px', fontWeight: 700, color: '#527EF7' }}>
          {days} dni
        </Typography>
      </Box>
    </Box>
  )
}

export default Countdown
