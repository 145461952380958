import { FC, useMemo, useState } from 'react'
import { Box, Button, Tabs, Tab, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { Link } from 'react-router-dom'
import CollapseSection from 'components/CollapseSection'
import MobileBottomFooter from 'components/MobileBottomFooter'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useLeaseContractProtocols } from 'services/protocols/protocols.hooks'
import {
  ProtocolMetersReadings,
  ProtocolRooms,
  ProtocolFurniture,
  ProtocolItems,
  ProtocolKeys
} from 'components/Protocols'

const AdditionalServicesProtocol: FC = () => {
  const [activeProtocol, setActiveProtocol] = useState<'Acceptance' | 'Delivery'>('Delivery')
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const { activeLeaseContractId } = useActiveLeaseContractId()
  const { protocolsList, acceptProtocol } = useLeaseContractProtocols(activeLeaseContractId)

  const protocol = useMemo(() => {
    if (!!protocolsList.length) {
      return protocolsList.find(({ protocolType }) => protocolType === activeProtocol)
    }

    return undefined
  }, [protocolsList, activeProtocol])

  const handleAcceptProtocol = async () => {
    try {
      await acceptProtocol.mutateAsync(protocol?.id)
      enqueueSnackbar(
        formatMessage({
          id: 'additionalServicesProtocolAccept.form.success',
          defaultMessage: 'Protokuł został złożny poprawie.'
        }),
        {
          variant: 'success'
        }
      )
    } catch (error) {
      enqueueSnackbar(
        formatMessage({
          id: 'additionalServicesProtocolAccept.form.error',
          defaultMessage: 'Nie można złożyć protokołu.'
        }),
        {
          variant: 'error'
        }
      )
    }
  }

  if (!!protocol) {
    return (
      <>
        <BackNavigationHeader
          label={formatMessage({
            id: 'acceptanceProtocolListItem.protocol',
            defaultMessage: 'Protokół Z-O'
          })}
        />
        <BackNavigationContent>
          <Box>
            <Tabs value={activeProtocol} onChange={(_, value) => setActiveProtocol(value)}>
              <Tab label='Zdawczy' value='Acceptance' />
              <Tab label='Odbiorczy' value='Delivery' />
            </Tabs>
          </Box>
          <CollapseSection
            title={formatMessage({
              id: 'acceptanceProtocolListItem.mediaMeter',
              defaultMessage: 'Liczniki'
            })}>
            <ProtocolMetersReadings readings={protocol.metersReadings} />
          </CollapseSection>

          <CollapseSection
            title={formatMessage({
              id: 'acceptanceProtocolListItem.rooms',
              defaultMessage: 'Pokoje'
            })}>
            <ProtocolRooms
              rooms={protocol.rooms}
              personComments={protocol.personComments}
              questionsAnswers={protocol.questionsAnswers}
              protocolId={protocol.id}
            />
          </CollapseSection>

          <CollapseSection
            title={formatMessage({
              id: 'acceptanceProtocolListItem.furniture',
              defaultMessage: 'Meble'
            })}>
            <ProtocolFurniture furniture={protocol.furniture} />
          </CollapseSection>

          <CollapseSection
            title={formatMessage({
              id: 'acceptanceProtocolListItem.items',
              defaultMessage: 'Przedmioty'
            })}>
            <ProtocolItems items={protocol.items} />
          </CollapseSection>

          <CollapseSection
            title={formatMessage({
              id: 'acceptanceProtocolListItem.keys',
              defaultMessage: 'Klucze'
            })}>
            <ProtocolKeys keys={protocol.keys} />
          </CollapseSection>

          {protocol.generalComment && (
            <CollapseSection title='Ogólny komentarz'>
              <Typography>{protocol.generalComment}</Typography>
            </CollapseSection>
          )}

          {protocol.status === 'Handed over' && (
            <MobileBottomFooter>
              <LoadingButton
                variant='contained'
                fullWidth
                loading={acceptProtocol.isLoading}
                disabled={acceptProtocol.isLoading}
                onClick={handleAcceptProtocol}>
                {formatMessage({
                  id: 'additionalServicesProtocolSpaces.form.accept',
                  defaultMessage: 'Zaakceptuj protokół'
                })}
              </LoadingButton>
            </MobileBottomFooter>
          )}
        </BackNavigationContent>
      </>
    )
  }

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'acceptanceProtocolListItem.protocol',
          defaultMessage: 'Protokół Z-O'
        })}
      />
      <BackNavigationContent>
        <Box>
          <Tabs value={activeProtocol} onChange={(_, value) => setActiveProtocol(value)}>
            <Tab label='Zdawczy' value='Acceptance' />
            <Tab label='Odbiorczy' value='Delivery' />
          </Tabs>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
          <Typography sx={{ my: 4 }}>
            {formatMessage({
              id: 'common.noData',
              defaultMessage: 'Brak danych'
            })}
          </Typography>
          <Button LinkComponent={Link} href={`/additional-services/${activeLeaseContractId}`}>
            {formatMessage({
              id: 'common.goBack',
              defaultMessage: 'Wróć'
            })}
          </Button>
        </Box>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesProtocol
