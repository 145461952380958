import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

type BenefitsListResponse = {
  id: number
  name: string
  description: string
  descriptionLocal: string
  logoThumbnail: string
  pdf: string
  domainName: string
  benefitCategory: {
    id: number
    namePl: string
    nameEn: string
  }
  investments: number[]
}[]

export const useBenefitsList = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: benefitsList, ...rest } = useQuery({
    queryKey: ['benefitsListv2', masquaradeToken],
    queryFn: () =>
      apiInstance<BenefitsListResponse>({
        url: '/benefits/benefit',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  const { data: benefitsCategories } = useQuery({
    queryKey: ['benefitsCategories', masquaradeToken],
    queryFn: () =>
      apiInstance<BenefitsListResponse>({
        url: '/benefits/benefit-categories',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { benefitsList, benefitsCategories, ...rest }
}
