import { Box } from '@mui/material'
import { FC } from 'react'
import { APIProvider, AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps'
import { HomeOutlined } from '@mui/icons-material'

type OfferMapProps = {
  geoposition: any
}

const OfferMap: FC<OfferMapProps> = ({ geoposition }) => {
  const { lat, lng } = geoposition

  return (
    <Box sx={{ marginTop: '20px', width: '100vw', height: '191px' }}>
      <APIProvider apiKey={'AIzaSyDg0paToUROEsCfNHR4oN1icU6pvdOLe7M'}>
        <Map
          style={{ width: '100%', height: '191px' }}
          defaultCenter={{ lat: lat, lng: lng }}
          defaultZoom={12}
          gestureHandling={'greedy'}
          mapId={'e11260affeeaa8ca'}>
          <AdvancedMarker position={geoposition}>
            <Pin
              background={'#527EF7'}
              glyphColor={'#000'}
              borderColor={'#fff'}
              children={<HomeOutlined style={{ color: '#fff' }} />}
            />
          </AdvancedMarker>
        </Map>
      </APIProvider>
    </Box>
  )
}

export default OfferMap
