import { useMutation, useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export interface Feedback {
  id: number
  length: number
  comment: string
}
export type useHelpdeskTicketsClientFeedbackResponse = Feedback[] | undefined

export const useHelpdeskTicketsClientFeedback = (id: number) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: getTicketFeedback, ...rest } = useQuery({
    queryKey: ['ticketFeedback', id, masquaradeToken],
    queryFn: () =>
      apiInstance<useHelpdeskTicketsClientFeedbackResponse>({
        url: `/helpdesk/tickets/${id}/client-feedback/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  })

  const { mutateAsync: createClientFeedback } = useMutation({
    mutationFn: ({
      feedback_type,
      comment,
      repair_assessment
    }: {
      feedback_type: string
      comment?: string
      repair_assessment?: number
    }) =>
      apiInstance<useHelpdeskTicketsClientFeedbackResponse>({
        url: `/helpdesk/tickets/${id}/create-client-feedback/`,
        method: 'POST',
        data: { feedback_type, comment, repair_assessment },
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data)
  })

  return {
    getTicketFeedback,
    createClientFeedback,
    ...rest
  }
}
