import { FC } from 'react'
import { CreditCardOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import queryString from 'query-string'
import env from 'consts/env'

import { CONTRACT_DOCUMENT_TYPE, ContractDocumentTypes } from 'services/contracts/contracts.consts'
import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import { useLeaseContractsPayment } from 'services/contracts/hooks/useLeaseContractsPayment'
import { useIntl } from 'react-intl'

interface AdditionalServicesOnlinePaymentButtonProps {
  documentType: ContractDocumentTypes
  documentId: number
  variant?: 'contained' | 'outlined' | 'text'
  fullWidth?: boolean
}

const AdditionalServicesOnlinePaymentButton: FC<AdditionalServicesOnlinePaymentButtonProps> = ({
  documentId,
  documentType,
  variant,
  fullWidth
}) => {
  const { formatMessage } = useIntl()
  const { activeLeaseContractId } = useActiveLeaseContractId()
  const {
    getDebitNotesPaymentDetails,
    getInterestNotesPaymentDetails,
    getInvoicesPaymentDetails,
    getDepositPaymentDetails,
    isLoading
  } = useLeaseContractsPayment({
    documentId,
    leaseContractId: activeLeaseContractId
  })

  const openPaymentWindow = ({
    paymentUrl,
    serviceId,
    amount,
    hashHex,
    id,
    title,
    receiverName,
    paymentAccount
  }: {
    amount: string
    hashHex: string
    id: number
    paymentAccount: string
    paymentUrl: string
    serviceId: string
    title: string
    receiverName: string
  }) => {
    const paymentUrlRerdirect = `${paymentUrl}?${queryString.stringify({
      ServiceID: serviceId,
      OrderID: id,
      Amount: amount,
      ReceiverName: receiverName,
      Title: title,
      CustomerNRB: paymentAccount,
      Hash: hashHex
    })}`

    if (!env.IS_PRODUCTION) {
      console.log('paymentUrl:', paymentUrlRerdirect, {
        ServiceID: serviceId,
        OrderID: id,
        Amount: amount,
        ReceiverName: receiverName,
        Title: title,
        CustomerNRB: paymentAccount,
        Hash: hashHex
      })
    }

    return (window.location.href = paymentUrlRerdirect)
  }

  const handleGetPaymentDetails = async () => {
    if (documentType === CONTRACT_DOCUMENT_TYPE.invoice) {
      const data = await getInvoicesPaymentDetails()
      if (!!data.length) {
        return openPaymentWindow(data[0])
      }
    }
    if (documentType === CONTRACT_DOCUMENT_TYPE.debitNote) {
      const data = await getDebitNotesPaymentDetails()
      if (!!data.length) {
        return openPaymentWindow(data[0])
      }
    }
    if (documentType === CONTRACT_DOCUMENT_TYPE.interestNote) {
      const data = await getInterestNotesPaymentDetails()
      if (!!data.length) {
        return openPaymentWindow(data[0])
      }
    }

    if (documentType === CONTRACT_DOCUMENT_TYPE.deposit) {
      const data = await getDepositPaymentDetails()
      if (!!data.length) {
        return openPaymentWindow(data[0])
      }
    }
  }

  return (
    <LoadingButton
      variant={variant || 'text'}
      onClick={handleGetPaymentDetails}
      loading={isLoading}
      fullWidth={fullWidth}
      startIcon={<CreditCardOutlined />}
    >
      {formatMessage({
        id: 'additionalServicesOnlinePaymentButton.pay',
        defaultMessage: 'Opłać'
      })}
    </LoadingButton>
  )
}

export default AdditionalServicesOnlinePaymentButton
