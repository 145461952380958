import { FC, ReactNode } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAccessToken } from 'services/token/token.hooks'

interface UnauthorizedRouteProps {
  children: ReactNode
}

const UnauthorizedRoute: FC<UnauthorizedRouteProps> = ({ children }) => {
  const { accessToken } = useAccessToken()
  const [searchParams] = useSearchParams()
  const toParam = searchParams.get('to')

  if (!accessToken) {
    return <>{children}</>
  }

  if (toParam) {
    return <Navigate to={toParam} />
  }

  return <Navigate to='/additional-services' />
}

export default UnauthorizedRoute
