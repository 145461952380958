import { FC } from 'react'

import dayjs from 'dayjs'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Section, { SectionButton, SectionCover } from 'components/Section'
import { useBoardsList, useBoardsListPosts } from 'services/boards/boards.hooks'
import InfoIndicator from 'components/InfoIndicator'
import { EventOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

const WidgetAnnouncements: FC = () => {
  const { data = [] } = useBoardsList()
  const { boardsListPosts, isLoading } = useBoardsListPosts(data.map(({ id }) => id))
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  return (
    <>
      <InfoIndicator
        helperText={formatMessage({
          id: 'WidgetAnnouncements.noEvents',
          defaultMessage: 'Brak wydarzeń'
        })}
        Icon={EventOutlined}
        isVisible={!boardsListPosts.length && !isLoading}
      />
      {boardsListPosts.map((post) => (
        <Section
          key={`${post.board.id}/${post.id}`}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            px: 0
          }}
        >
          <Box width='100%' px={2} mb={1}>
            {!!post.multimedia.length && (
              <SectionCover src={post.multimedia[0].multimedia} alt='' />
            )}
          </Box>
          <Typography sx={{ width: '100%', px: 2, fontWeight: 500 }}>{post.title}</Typography>
          <Typography variant='body2' sx={{ width: '100%', px: 2 }}>
            {dayjs(post.eventDate).format('DD/MM/YYYY HH:mm')}
          </Typography>
          <SectionButton onClick={() => navigate(`/board/${post.board.id}/${post.id}`)}>
            {post.createdBy.firstName}
          </SectionButton>
        </Section>
      ))}
    </>
  )
}

export default WidgetAnnouncements
