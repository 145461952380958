import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import type { LeaseContractResponse } from './useLeaseContracts'

export interface LeaseContractsPaymentResponse {
  amount: string
  hashHex: string
  id: number
  paymentAccount: string
  paymentUrl: string
  serviceId: string
  title: string
  receiverName: string
}

export interface LeaseContractsPaymentRequest {
  leaseContractId: LeaseContractResponse['id']
  documentId: number
}

export const useLeaseContractsPayment = ({
  documentId,
  leaseContractId
}: LeaseContractsPaymentRequest) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { mutateAsync: mutateAsyncInterestNotes, isLoading: isLoadingInterestNotes } = useMutation({
    cacheTime: 0,
    mutationFn: () =>
      apiInstance<LeaseContractsPaymentResponse[]>({
        url: `/lease/contracts/${leaseContractId}/interest-notes/${documentId}/payment-details/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data)
  })

  const { mutateAsync: mutateAsyncInvoices, isLoading: isLoadingInvoices } = useMutation({
    cacheTime: 0,
    mutationFn: () =>
      apiInstance<LeaseContractsPaymentResponse[]>({
        url: `/lease/contracts/${leaseContractId}/invoices/${documentId}/payment-details/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data)
  })

  const { mutateAsync: mutateAsyncDebitNotes, isLoading: isLoadingDebitNotes } = useMutation({
    cacheTime: 0,
    mutationFn: () =>
      apiInstance<LeaseContractsPaymentResponse[]>({
        url: `/lease/contracts/${leaseContractId}/debit-notes/${documentId}/payment-details/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data)
  })

  const { mutateAsync: mutateAsyncDeposit, isLoading: isLoadingDeposit } = useMutation({
    cacheTime: 0,
    mutationFn: () =>
      apiInstance<LeaseContractsPaymentResponse[]>({
        url: `/lease/contracts/${leaseContractId}/deposit-payment-details/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data)
  })

  return {
    getDebitNotesPaymentDetails: mutateAsyncDebitNotes,
    getInvoicesPaymentDetails: mutateAsyncInvoices,
    getInterestNotesPaymentDetails: mutateAsyncInterestNotes,
    getDepositPaymentDetails: mutateAsyncDeposit,
    isLoading:
      isLoadingInterestNotes || isLoadingInvoices || isLoadingDebitNotes || isLoadingDeposit
  }
}
