import { useMemo } from 'react'
import { apiInstance } from 'services/api'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'

interface UseObtainParams {
  onError: () => void
}

export const useObtain = ({ onError }: UseObtainParams) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => {
      const emailToLowerCase = email.toLowerCase()
      return apiInstance<{ access: string; refresh: string }>({
        url: '/users/token/obtain/',
        method: 'POST',
        data: {
          email: emailToLowerCase,
          password
        }
      })
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['accessToken'], data.access)
      queryClient.setQueryData(['refreshToken'], data.refresh)
    },
    onError
  })
}

export const useAccessToken = () => {
  const localStorageOfflineCache = localStorage.getItem('REACT_QUERY_OFFLINE_CACHE')
  const isCacheReady = useMemo(() => {
    if (!!localStorageOfflineCache) {
      const parsedLocalStorageOfflineCache = JSON.parse(localStorageOfflineCache)
      return !!parsedLocalStorageOfflineCache.clientState.queries.length
    }

    return false
  }, [localStorageOfflineCache])

  const {
    data: accessToken,
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['accessToken'],
    queryFn: () => null,
    enabled: false
  })

  const clearUserData = async () => {
    await refetch()
  }

  return { accessToken, clearUserData, isCacheReady, ...rest }
}
export const useRefreshToken = () => {
  const {
    data: refreshToken,
    refetch: clearRefreshToken,
    ...rest
  } = useQuery({
    queryKey: ['refreshToken'],
    queryFn: () => null,
    enabled: false
  })

  return { refreshToken, clearRefreshToken, ...rest }
}

export const useVerifyToken = () => {
  // const queryClient = useQueryClient();
  // const { clearUserData } = useAccessToken();

  return useMutation({
    mutationFn: (token: string) =>
      apiInstance<{ detail: string; code: string }>({
        url: '/users/token/verify/',
        method: 'POST',
        data: {
          token
        }
      }),
    onError: () => {}
  })
}

export const useUserPasswordReset = () => {
  return useMutation({
    mutationFn: ({ password, token }: { password: string; token: string }) =>
      apiInstance<any>({
        url: '/users/password/reset/',
        method: 'POST',
        data: { password, resetKey: token }
      })
  })
}
