import { FC } from 'react'
import { SpeedDial, useTheme, SpeedDialProps } from '@mui/material'
import { Add } from '@mui/icons-material'
const ComponentName: FC<SpeedDialProps> = (props) => {
  const theme = useTheme()
  return (
    <SpeedDial
      sx={{
        position: 'fixed',
        bottom: 'calc(70px + env(safe-area-inset-bottom))',
        right: '1rem',
        [theme.breakpoints.up('md')]: {
          bottom: 'calc(2rem + env(safe-area-inset-bottom))',
          right: '2rem'
        }
      }}
      icon={<Add />}
      {...props}
    ></SpeedDial>
  )
}

export default ComponentName
