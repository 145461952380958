import { LoadingButton } from '@mui/lab'
import { Box, Button, Rating, TextField, Typography } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useHelpdeskTicketsList } from 'services/helpdesk/hooks'
import { useHelpdeskTicketsClientFeedback } from 'services/helpdesk/hooks/useHelpdeskTicketsClientFeedback'
import StarIcon from '@mui/icons-material/Star'
import Defect from './Defect'

const DefectFeedback = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { getTicket, getSatusColors } = useHelpdeskTicketsList()
  const { getTicketFeedback, createClientFeedback } = useHelpdeskTicketsClientFeedback(Number(id))
  const { formatMessage } = useIntl()
  const ticket = getTicket(Number(id))
  const ticketFeedback = getTicketFeedback
  const [isFeedbackSent, setIsFeedbackSent] = useState(false)
  const [isFixed, setIsFixed] = useState(false)
  const [showComment, setShowComment] = useState(false)

  const formik = useFormik<{
    id: string
    comment: string
    repair_assessment: number
    feedback_type: string
  }>({
    initialValues: { id: '', comment: '', repair_assessment: 0, feedback_type: '' },
    onSubmit: async ({ comment }, { setSubmitting }) => {
      setSubmitting(true)
      await createClientFeedback({
        feedback_type: ticket?.status.code === 'READY' ? 'done' : 'not done',
        comment: comment,
        repair_assessment: formik.values.repair_assessment * 2
      })
      setSubmitting(false)
      setIsFeedbackSent(true)
    }
  })

  const handleRejectFix = async (comment: string) => {
    await createClientFeedback({
      feedback_type: 'not done',
      comment: comment
    })

    navigate(`/faults`)
  }

  if (ticket) {
    return (
      <>
        <DesktopNavigation
          desktopNavigateTo='/faults'
          label={
            <>
              {ticket?.topic}
              <Typography
                variant='caption'
                component='span'
                sx={{
                  display: 'inline-flex',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  fontWeight: 500,
                  marginLeft: '8px',
                  ...getSatusColors(ticket?.status.code)
                }}>
                {ticket?.status.name}
              </Typography>
            </>
          }
        />
        <BackNavigationHeader
          label={
            <>
              {ticket.topic}{' '}
              <Typography
                variant='caption'
                component='span'
                sx={{
                  display: 'inline-flex',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  fontWeight: 500,
                  marginLeft: '8px',
                  ...getSatusColors(ticket.status.code)
                }}>
                {ticket.status.name}
              </Typography>
            </>
          }
        />
        <BackNavigationContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            marginTop: '30px'
          }}>
          <>
            {!isFixed && ticketFeedback?.length === 0 ? (
              <Box>
                <Typography sx={{ textAlign: 'center' }}>
                  {formatMessage({
                    id: 'ticketFeedback.isFixed',
                    defaultMessage: 'Czy usterka została naprawiona?'
                  })}
                </Typography>
                <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                  <Button onClick={() => setShowComment(true)} fullWidth variant='outlined'>
                    {formatMessage({
                      id: 'ticketFeedback.no',
                      defaultMessage: 'Nie'
                    })}
                  </Button>
                  <LoadingButton
                    onClick={() => setIsFixed(true)}
                    color='primary'
                    fullWidth
                    variant='contained'>
                    {formatMessage({
                      id: 'ticketFeedback.yes',
                      defaultMessage: 'Tak'
                    })}
                  </LoadingButton>
                </Box>
                {showComment && (
                  <Box sx={{ marginTop: '20px' }}>
                    <Typography>
                      {formatMessage({
                        id: 'ticketFeedback.comment',
                        defaultMessage: 'Czy powinniśmy zwrócić na coś większą uwagę?'
                      })}
                    </Typography>
                    <TextField
                      name='comment'
                      onChange={formik.handleChange}
                      value={formik.values.comment}
                      fullWidth
                      rows={5}
                      multiline
                      disabled={formik.isSubmitting}
                      sx={{ marginTop: '10px' }}
                    />
                    <LoadingButton
                      onClick={() => handleRejectFix(formik.values.comment)}
                      color='primary'
                      fullWidth
                      variant='contained'
                      loading={formik.isSubmitting}
                      sx={{ marginTop: '20px' }}>
                      {formatMessage({
                        id: 'boardsNewPost.form.submit',
                        defaultMessage: 'WYŚLIJ'
                      })}
                    </LoadingButton>
                  </Box>
                )}
              </Box>
            ) : (
              <>
                {(ticketFeedback && ticketFeedback.length > 0) || isFeedbackSent ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Box sx={{ marginTop: { xs: '-60px', md: '0px' } }}>
                      <Defect />
                    </Box>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        borderTop: '1px solid #CDCDCD',
                        paddingTop: '10px'
                      }}>
                      {formatMessage({
                        id: 'ticketFeedback.answerSent',
                        defaultMessage: 'Odpowiedź została przesłana, dziękujemy'
                      })}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                      <Box>
                        <Typography>
                          {formatMessage({
                            id: 'ticketFeedback.rate',
                            defaultMessage:
                              'Jak oceniasz szybkość i jakość rozwiązania usterki przez nasz zespó'
                          })}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                          <Rating
                            size='large'
                            name='repair_assessment'
                            value={formik.values.repair_assessment}
                            precision={0.5}
                            onChange={formik.handleChange}
                            max={5}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography>
                          {formatMessage({
                            id: 'ticketFeedback.comment',
                            defaultMessage: 'Czy powinniśmy zwrócić na coś większą uwagę?'
                          })}
                        </Typography>
                        <TextField
                          name='comment'
                          onChange={formik.handleChange}
                          value={formik.values.comment}
                          fullWidth
                          rows={5}
                          multiline
                          disabled={formik.isSubmitting}
                          sx={{ marginTop: '10px' }}
                        />
                      </Box>
                    </Box>
                    <LoadingButton
                      onClick={formik.submitForm}
                      color='primary'
                      fullWidth
                      variant='contained'
                      loading={formik.isSubmitting}>
                      {formatMessage({
                        id: 'boardsNewPost.form.submit',
                        defaultMessage: 'WYŚLIJ'
                      })}
                    </LoadingButton>
                  </>
                )}
              </>
            )}
          </>
        </BackNavigationContent>
      </>
    )
  }
  return null
}

export default DefectFeedback
