import React from 'react'
import ReactDOM from 'react-dom/client'
import ThemeProvider from 'styles/ThemeProvider'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'

import Router from 'routes/Router'
import IntlProvider from 'components/IntlProvider'

import 'dayjs/locale/pl'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3600000, // 1 hour
      cacheTime: 3600000, // 1 hour
      retry: false
    }
  }
})

const persister = createSyncStoragePersister({
  storage: window.localStorage
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <IntlProvider>
        <ThemeProvider>
          <SnackbarProvider
            autoHideDuration={6000}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <Router />
          </SnackbarProvider>
        </ThemeProvider>
      </IntlProvider>
    </PersistQueryClientProvider>
  </React.StrictMode>
)
