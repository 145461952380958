import { FC } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useOfferList } from 'services/offer/hooks'
import { LocationOnOutlined, CalendarTodayOutlined, HomeOutlined } from '@mui/icons-material'
import Section, { SectionCover } from 'components/Section'
import { OfferButton } from './Offer'
import { useIntl } from 'react-intl'
import Countdown from './Countdown'
import dayjs from 'dayjs'

const OfferList: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data: offerList = [], isFetching, isLoading } = useOfferList()

  if (isFetching || isLoading) {
    return (
      <Section
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: { xs: '-50px', lg: '20px' }
        }}>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <Box sx={{ marginTop: { xs: '-50px', lg: '20px' } }}>
      {offerList.length <= 0 && (
        <Section
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '15px'
          }}>
          <Typography variant='h5' fontWeight={500}>
            {formatMessage({
              id: 'widgetOffer.noActiveOffers',
              defaultMessage: 'Nie masz aktywnych ofert, poproś o nową ofertę'
            })}
          </Typography>
          <Button variant='contained' fullWidth>
            {formatMessage({
              id: 'widgetOffer.askForOffer',
              defaultMessage: 'Poproś o ofertę'
            })}
          </Button>
        </Section>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {offerList?.map(({ id, flatType, flat, investmentName, price, validTo, availableOn }) => (
          <Section
            key={`${id}`}
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              mx: '18px',
              maxWidth: '530px'
            }}>
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  marginBottom: '14px'
                }}>
                <SectionCover
                  src={flat?.photos[0]?.file}
                  alt=''
                  sx={{
                    height: '137px',
                    minWidth: '300px',
                    maxWidth: '680px',
                    objectFit: 'cover'
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ width: '100%', height: '100%', fontWeight: 600, fontSize: 20 }}>
                  {investmentName}
                </Typography>
                <Countdown deadline={validTo} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '9px',
                  marginBottom: '54px',
                  gap: '9px'
                }}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                  <LocationOnOutlined sx={{ width: '16px', height: '16px' }} />
                  <Typography sx={{ width: '100%', fontWeight: 500, fontSize: '14px' }}>
                    {flat.address}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                  <HomeOutlined sx={{ width: '16px', height: '16px' }} />
                  <Typography sx={{ width: '100%', fontWeight: 500, fontSize: '14px' }}>
                    {flatType}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                  <CalendarTodayOutlined sx={{ width: '16px', height: '16px' }} />
                  <Typography sx={{ width: '100%', fontWeight: 500, fontSize: '14px' }}>
                    {dayjs(availableOn).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                  }}>
                  <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>{+price} zł</Typography>
                  <Typography sx={{ fontWeight: 500 }}>/ms</Typography>
                </Box>
                <OfferButton
                  children={formatMessage({
                    id: 'offer.seeTheOffer',
                    defaultMessage: 'Obejrzyj'
                  })}
                  sx={{ maxHeight: '26px', cursor: 'pointer' }}
                  onClick={() => navigate(`/offer/${id}`)}
                />
              </Box>
            </Box>
          </Section>
        ))}
      </Box>
    </Box>
  )
}

export default OfferList
