import { useQueryClient, useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { isNil } from 'ramda'
import { CONTRACT_VISIBLE_GROUP, CONTRACT_ACTIVE_GROUP } from '../contracts.consts'

export interface LeaseContractResponse {
  id: number
  leaseStatus: number
  contractNumber: string
  leaseContractFile: string
  leaseStart: string
  leaseEnd: string
  currency: string
  paymentCurrency: string
}

export const useLeaseContractsList = () => {
  const { accessToken } = useAccessToken()
  const queryClient = useQueryClient()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: leaseContractsList = [], ...rest } = useQuery({
    queryKey: ['leaseContractsList', masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractResponse[]>({
        url: '/lease/contracts/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    select: (data) =>
      data.filter(
        ({ contractNumber, leaseStatus }) =>
          !isNil(contractNumber) && CONTRACT_VISIBLE_GROUP.includes(leaseStatus)
      ),
    onSuccess: (data) => {
      const defaultActiveContract = data.find(({ leaseStatus }) =>
        CONTRACT_ACTIVE_GROUP.includes(leaseStatus)
      )

      if (!!defaultActiveContract) {
        queryClient.setQueryData(
          ['activeLeaseContractId', masquaradeToken],
          defaultActiveContract.id
        )
      }
    },
    enabled: !!accessToken
  })

  const getActiveContract = (id: string | number) =>
    leaseContractsList.find((contract) => contract.id === Number(id))

  return { leaseContractsList, getActiveContract, ...rest }
}

export const useLeaseContract = (id: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: leaseContract, ...rest } = useQuery({
    queryKey: ['leaseContract', id, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractResponse>({
        url: `/lease/contracts/${id}/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { leaseContract, ...rest }
}
