import { FC } from 'react'
import { Box, Typography } from '@mui/material'

const ProtocolAuestionsAnswers: FC<{
  questions: {
    id: number
    room: {
      id: number
      roomInstance: {
        id: number
        name: string
        nameLocal: string
        flat: {
          id: number
          area: number
          floor: number
          flatIdNumber: string
          numberOfRooms: number
          flatType: number
          flatTypeExtra: number
        }
        area: number
      }
    }
    question: {
      id: number
      roomType: number
      question: string
    }
    scale: string
    comment: string
    image: string
    debitNotePosition: {
      id: number
      title: string
      productCode: number
    }
    coveringCost: string
  }[]
}> = ({ questions }) => {
  if (questions.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography>Brak pytań</Typography>
      </Box>
    )
  }

  return (
    <>
      {questions.map((question) => (
        <Box key={question.id} mb={2}>
          <Typography fontWeight={800}>{question.question.question}</Typography>
          {question.scale === 'Issue' ? (
            <Box>
              <Typography>Uwagi: {question.comment}</Typography>
              <Typography>Koszt pokrycia: {question.coveringCost}</Typography>
              <Typography>Nota obciążeniowa: {question.debitNotePosition.title}</Typography>

              {question.image && (
                <Box
                  mt={2}
                  component='img'
                  srcSet={question.image}
                  src={question.image}
                  alt={question.comment}
                  loading='lazy'
                  sx={{ width: 'auto', maxHeight: '320px' }}
                />
              )}
            </Box>
          ) : (
            <Typography>Brak zastrzeżeń</Typography>
          )}
        </Box>
      ))}
    </>
  )
}

export default ProtocolAuestionsAnswers
