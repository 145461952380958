import { FC } from 'react'

import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CircularProgress
} from '@mui/material'
import { KeyboardArrowRight, HelpOutlineRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDocumentsList } from 'services/documents/documents.hooks'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useLanguage } from 'services/i18n/i18n.hooks'

const WidgetHelp: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { language } = useLanguage()
  const { data: attachmentsList = [], isFetching, isLoading } = useDocumentsList()

  const attachments = attachmentsList
    .filter(({ displayInHelp }) => displayInHelp)
    .filter((item) => !item.language || item.language.isoCode === language)
    .sort((a, b) => a.name.localeCompare(b.name))

  if (isFetching || isLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }

  if (attachments.length) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant='h5' fontWeight={500}>
          {formatMessage({
            id: 'widgetHelp.help',
            defaultMessage: 'Strefa Pomocy'
          })}
        </Typography>
        <List
          sx={{
            width: '100%',
            '.MuiListItem-root:last-child': {
              borderBottom: 'none'
            }
          }}>
          {attachments.map(({ name, id }) => (
            <ListItem
              key={id}
              onClick={() => navigate(`/help/${id}`)}
              disablePadding
              sx={{
                borderBottom: '1px solid #CDCDCD'
              }}
              secondaryAction={<KeyboardArrowRight color='disabled' />}>
              <ListItemButton sx={{ py: 2 }}>
                <ListItemIcon>
                  <HelpOutlineRounded color='primary' />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Section>
    )
  }

  return null
}

export default WidgetHelp
