import { FC, useEffect } from 'react'
import { TextField, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'

import PasswordField from 'components/PasswordField'
import { useObtain } from 'services/token/token.hooks'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

const Login: FC = () => {
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const navigation = useNavigate()
  const obtain = useObtain({
    onError: () => {
      enqueueSnackbar(
        formatMessage({
          id: 'validation.obtain',
          defaultMessage: 'Błąd logowania. Podano nieprawidłowy login lub hasło.'
        }),
        {
          variant: 'error'
        }
      )
    }
  })

  const form = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          formatMessage({
            id: 'validation.email',
            defaultMessage: 'Niepoprawny adres email'
          })
        )
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        ),
      password: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      )
    }),
    onSubmit: async (values) => {
      await obtain.mutate(values)
    }
  })

  useEffect(() => {
    queryClient.invalidateQueries()
    // eslint-disable-next-line
  }, [])

  return (
    <form onSubmit={form.handleSubmit}>
      <TextField
        name='email'
        value={form.values.email}
        onChange={form.handleChange}
        fullWidth
        placeholder={formatMessage({
          id: 'login.form.email',
          defaultMessage: 'Email'
        })}
        label={formatMessage({
          id: 'login.form.email',
          defaultMessage: 'Email'
        })}
        disabled={obtain.isLoading}
        sx={{ mb: 3 }}
        error={!!form.errors.email}
        helperText={!!form.errors.email && form.errors.email}
      />
      <PasswordField
        name='password'
        value={form.values.password}
        onChange={form.handleChange}
        fullWidth
        sx={{ mb: 3 }}
        label={formatMessage({
          id: 'login.form.password',
          defaultMessage: 'Hasło'
        })}
        disabled={obtain.isLoading}
        error={!!form.errors.password}
        helperText={!!form.errors.password && form.errors.password}
      />
      <LoadingButton
        fullWidth
        size='large'
        variant='contained'
        sx={{ mb: 1 }}
        type='submit'
        loading={obtain.isLoading}>
        {formatMessage({
          id: 'login.form.submit',
          defaultMessage: 'ZALOGUJ SIĘ'
        })}
      </LoadingButton>
      {/*NOTE: hidden sign up link */}
      {/* <Button
        onClick={() => navigation('/auth/sign-up')}
        color='secondary'
        fullWidth
        size='large'
        variant='text'
        sx={{ textTransform: 'none', fontWeight: '400' }}
      >
        {formatMessage({
          id: 'login.form.signup',
          defaultMessage: 'Nie masz konta? Zarejestruj się'
        })}
      </Button> */}
      <Button
        onClick={() => navigation('/auth/reset-password')}
        color='secondary'
        fullWidth
        size='large'
        variant='text'
        sx={{ mb: 1, textTransform: 'none', fontWeight: '400' }}>
        {formatMessage({
          id: 'login.form.resetPassword',
          defaultMessage: 'Nie pamiętam hasła'
        })}
      </Button>
    </form>
  )
}

export default Login
