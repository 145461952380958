import { Box, CircularProgress, Typography } from '@mui/material'
import BackNavigationHeader from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import Section from 'components/Section'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useOfferListOffer } from 'services/offer/hooks/useOfferList'
import { APIProvider, AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps'
import { getLatLng } from 'utils'
import { HomeOutlined } from '@mui/icons-material'

const OfferPoisMap = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { data: offerDetail = [], isFetching, isLoading } = useOfferListOffer(id)

  if (isFetching || isLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }

  const propertyCoords = getLatLng(offerDetail.flat.geoposition)

  const Pois = ({ points }: any) => {
    return (
      <>
        {points?.map((point: { id: number; geoposition: string; iconUrl: string }) => (
          <AdvancedMarker key={point.id} position={getLatLng(point.geoposition)}>
            <Pin
              background={'#527EF7'}
              glyphColor={'#000'}
              borderColor={'#fff'}
              scale={1.5}
              children={
                <Box
                  component='img'
                  src={point.iconUrl}
                  sx={{ width: '24px', height: '24px', objectFit: 'contain' }}
                />
              }
            />
          </AdvancedMarker>
        ))}
      </>
    )
  }

  return (
    <>
      <DesktopNavigation
        label={formatMessage({
          id: 'offer.map',
          defaultMessage: 'Mapa'
        })}
      />
      <BackNavigationHeader
        label={formatMessage({
          id: 'offer.map',
          defaultMessage: 'Mapa'
        })}
      />
      <APIProvider apiKey={'AIzaSyDg0paToUROEsCfNHR4oN1icU6pvdOLe7M'}>
        <Map
          style={{ width: '100vw', height: '100vh', position: 'relative' }}
          defaultCenter={{
            lat: propertyCoords.lat,
            lng: propertyCoords.lng
          }}
          defaultZoom={14}
          gestureHandling={'greedy'}
          mapId={'e11260affeeaa8ca'}
          zoomControl={false}
          streetViewControl={false}>
          <AdvancedMarker position={propertyCoords}>
            <Pin
              background={'#527EF7'}
              glyphColor={'#000'}
              borderColor={'#fff'}
              children={<HomeOutlined style={{ color: '#fff' }} />}
            />
          </AdvancedMarker>
          <Pois points={offerDetail.flat.pois} />
          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              position: 'absolute',
              bottom: 25,
              left: 10,
              gap: '14px',
              maxWidth: { xs: '370px', md: '100vw' }
            }}>
            {offerDetail?.flat?.pois.map(
              (poi: {
                id: number
                distance: string
                distanceUnit: number
                name: string
                iconUrl: string
              }) => (
                <Box
                  key={poi.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    height: 80,
                    minWidth: '228px',
                    maxWidth: '228px',
                    backgroundColor: '#FFF',
                    border: '1px solid #0000001F',
                    borderRadius: '5px',
                    paddingX: '12px',
                    paddingY: '16px'
                  }}>
                  <Box
                    component='img'
                    src={poi.iconUrl}
                    sx={{
                      width: 39,
                      height: 39,
                      objectFit: 'contain'
                    }}></Box>
                  <Box>
                    <Typography sx={{ fontSize: '15px', fontWeight: 500 }}>{poi.name}</Typography>
                    <Typography
                      sx={{
                        fontSize: '15px',
                        color: '#707070',
                        fontWeight: 600
                      }}>{`${poi.distance} ${poi.distanceUnit === 0 ? 'm' : 'km'}`}</Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Map>
      </APIProvider>
    </>
  )
}

export default OfferPoisMap
