import { Box, Button, Typography } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const OfferAccept = () => {
  const navigation = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <>
      <DesktopNavigation
        label={formatMessage({
          id: 'offer.backToPanel',
          defaultMessage: 'Wróć do panelu'
        })}
      />
      <BackNavigationHeader
        label={formatMessage({
          id: 'offer.backToPanel',
          defaultMessage: 'Wróć do panelu'
        })}
      />
      <BackNavigationContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          height: '100vh'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px'
          }}>
          <Typography
            sx={{ fontWeight: 600, fontSize: '26px', lineHeight: '35px', textAlign: 'center' }}>
            {formatMessage({
              id: 'offer.acceptMessage',
              defaultMessage:
                'Dziękujemy za zaakceptowanie oferty, Twój opiekun skontaktuje się z Tobą w celu podpisania umowy.'
            })}
          </Typography>
          <Button
            variant='contained'
            sx={{ width: '315px', marginTop: '20px' }}
            onClick={() => navigation('/')}>
            {formatMessage({
              id: 'offer.backToPanel',
              defaultMessage: 'Wróć do panelu'
            })}
          </Button>
        </Box>
      </BackNavigationContent>
    </>
  )
}

export default OfferAccept
